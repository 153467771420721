
import {documentsModule} from '@/store'
import {Component, Prop, Vue} from 'vue-property-decorator'
import {DocumentPresetResource} from '@/models/documents/DocumentPresetResource'
import {DocumentFilterResource} from '@/models/documents/DocumentFilterResource'
import {DocumentExportByPresetRequest} from '@/models/documents/DocumentExportByPresetRequest'

import DateInput from '@/components/inputs/Date.vue'
import RadioInput from '@/components/inputs/Radio.vue'
import DefaultModal from '@/components/modals/Default.vue'
import DropdownInput from '@/components/inputs/Dropdown.vue'
import FilterTooltip from '@/views/dashboard/projects/partials/FilterTooltip.vue'

@Component({
  components: {
    DateInput,
    RadioInput,
    DefaultModal,
    DropdownInput,
    FilterTooltip
  }
})
export default class DocumentDetailNotesPartial extends Vue {

  @Prop()
  private isOpen!: boolean

  @Prop()
  private preset?: DocumentPresetResource

  @Prop()
  private presets!: SelectItem[]

  @Prop()
  private filters!: Array<DocumentFilterResource & { value: string | Array<string | null> }>

  private isExporting: boolean = false

  private form: { type: 'presets' | 'filters', preset_ids: number[], from_date: string, enableFromDate: boolean } = {
    type: 'filters',
    preset_ids: [],
    from_date: '',
    enableFromDate: true
  }

  private get hasFiltersSelected(): boolean {
    return this.filters.length > 0
  }

  private close() {
    this.$emit('close')
    this.resetForm()
  }

  private resetForm() {
    this.form.type = 'filters'
    this.form.preset_ids = []
    this.form.from_date = ''
    this.form.enableFromDate = true
  }

  private exported() {
    this.$emit('submit')
    this.close()
  }

  private async submit() {
    this.isExporting = true
    try {
      if (this.form.type === 'filters') {
        const body = {...documentsModule.query}
        if (this.form.from_date) body.from_date = this.form.from_date
        await documentsModule.exportLibraryContents(body)
      } else if (this.form.type === 'presets') {
        const body = new DocumentExportByPresetRequest({preset_ids: this.form.preset_ids})
        if (this.form.from_date) body.from_date = this.form.from_date
        await documentsModule.exportLibraryByPresets(body)
      }

      this.$emit('exported')
      this.close()
    } catch (e) {
      console.error(e)
    } finally {
      this.isExporting = false
    }
  }

}
