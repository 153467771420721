

























































































































































































































































































































































import { Route } from 'vue-router'
import isEqual from 'lodash/isEqual'
import orderBy from 'lodash/orderBy'
import { cleanObject } from '@/helpers/query'
import { DocumentsService } from '@/services/documents'
import { Component, Vue } from 'vue-property-decorator'
import { searchTooltip } from '@/helpers/searchTooltipText'
import { OrganisationService } from '@/services/organisation'
import { mixin as clickaway } from '@/plugins/vue-clickaway.ts'
import { documentsModule, stateModule, userModule } from '@/store'

import UploadModal from './partials/Upload.vue'
import TableRow from '@/components/table/Row.vue'
import TextInput from '@/components/inputs/Text.vue'
import DateInput from '@/components/inputs/Date.vue'
import SearchInput from '@/components/inputs/Search.vue'
import SubHeader from '@/components/header/SubHeader.vue'
import TableWrapper from '@/components/table/Wrapper.vue'
import TableHeading from '@/components/table/Heading.vue'
import ExportModal from '@/components/document/Export.vue'
import PresetActions from '@/components/preset/Actions.vue'
import CheckboxInput from '@/components/inputs/Checkbox.vue'
import DropdownInput from '@/components/inputs/Dropdown.vue'
import MarkAsRead from '@/components/document/MarkAsRead.vue'
import DocSelected from '@/components/widgets/DocSelected.vue'
import ExportedModal from '@/components/document/Exported.vue'
import Pagination from '@/components/paginations/Pagination.vue'
import CreatePresetModal from '@/components/preset/CreateModal.vue'
import ConfirmPresetUpdate from '@/components/preset/ConfirmUpdate.vue'
import FilterTooltip from '@/views/dashboard/projects/partials/FilterTooltip.vue'

import { AuthUserResource } from '@/models/users/AuthUserResource'
import { DocumentPresetResource } from '@/models/documents/DocumentPresetResource'
import { DocumentFilterResource } from '@/models/documents/DocumentFilterResource'
import { DocumentCollectionResource } from '@/models/documents/DocumentCollectionResource'

import { DocumentIndexRequest } from '@/requests/documents/DocumentIndexRequest'

const DEFAULT_FILTERS = {
  country: [],
  embedded: '',
  end_date: '',
  start_date: '',
  issuer: [],
  subject: [],
  topic: [],
  type: []
}

@Component({
  mixins: [clickaway],
  components: {
    MarkAsRead,
    TableRow,
    SubHeader,
    TextInput,
    DateInput,
    Pagination,
    SearchInput,
    UploadModal,
    ExportModal,
    DocSelected,
    TableWrapper,
    TableHeading,
    PresetActions,
    FilterTooltip,
    DropdownInput,
    CheckboxInput,
    ExportedModal,
    CreatePresetModal,
    ConfirmPresetUpdate,
  },
})
export default class DocumentsOverview extends Vue {
  // Data
  private showFilters: boolean = false
  private showAllMinePresets: boolean = false
  private showAllPredefinedPresets: boolean = false
  private organisationService: OrganisationService = new OrganisationService()
  private documentsService: DocumentsService = new DocumentsService()

  private countries: SelectItem[] = []
  private departments: SelectItem[] = []

  private searchTooltip: string = searchTooltip

  private isCreatePresetModalOpen: boolean = false
  private confirmPatchPresetModalOpen: boolean = false
  private isExportedModalOpen: boolean = false
  private isExportModalOpen: boolean = false
  private isMarkAsReadModalOpen: boolean = false
  private isUploadModalOpen: boolean = false

  // Computed
  private get showUpdatePresetButton(): boolean {
    let show: boolean = false
    const selectedPreset = this.selectedPreset
    if (this.filters && selectedPreset) {
      const allFilters = this.filters.map((filter: DocumentFilterResource) => {
        const selectedPresetFilter = selectedPreset.values.find(
          ({ key }) => filter.key === key,
        )
        return {
          presetValues: selectedPresetFilter
            ? selectedPresetFilter.values
            : filter.multiple
            ? []
            : undefined,
          selectedValues: this.selectedFilters[filter.key],
        }
      })

      // Add search
      allFilters.push({
        presetValues: this.selectedPreset?.values.find((v) => v.key === 'search')?.values,
        selectedValues: this.selectedFilters.search
      })

      // If value of a preset if different than what is selected within a specific filter the preset is different and could be updated
      for (const filter of allFilters) {
        if (!isEqual(filter.presetValues, filter.selectedValues) &&
            !(filter.presetValues === null && filter.selectedValues === '') &&
            !(filter.selectedValues === null && filter.presetValues === '')) {
          show = true
        }
      }
    }
    return show
  }

  private get user(): AuthUserResource | null {
    return userModule.user || null
  }

  private get loading(): boolean {
    return stateModule.loading
  }

  private get index(): IndexResponse<DocumentCollectionResource> | null {
    return documentsModule.index
  }

  private get canStartProject(): boolean {
    return userModule.canPerformAction('can_start_project')
  }

  private get query(): DocumentIndexRequest & IndexParameters {
    return documentsModule.query
  }

  private get selectedFilters(): DocumentIndexRequest {
    return documentsModule.selectedFilters
  }

  private get selectedFiltersObject(): Array<
    DocumentFilterResource & { value: string | Array<string | null> }
  > {
    const FILTER_TYPES: Array<
      DocumentFilterResource & { value: string | Array<string | null> }
    > = []
    const filters = this.filters
    if (filters) {
      Object.keys(this.selectedFilters).forEach((key) => {
        // If selected filter is a value && (value is typeof string) OR (value is typeof OBJECT check if array is filled)
        const selectedFilter = this.selectedFilters[key]
        if (
          selectedFilter &&
          (typeof selectedFilter === 'string' ||
            (typeof selectedFilter === 'object' && selectedFilter.length > 0))
        ) {
          const filter = filters.find((f) => f.key === key)
          if (filter) {
            FILTER_TYPES.push({
              value: selectedFilter,
              ...filter,
            })
          }
        }
      })
    }
    return FILTER_TYPES
  }

  private get hasFiltersSelected(): boolean {
    return this.selectedFiltersObject.length > 0
  }

  private canFilterSearch(filter: DocumentFilterResource): boolean {
    return ['type', 'issuer', 'subject', 'topic', 'country', 'theme', 'service_line'].includes(filter.key)
  }

  private get filters(): DocumentFilterResource[] | null {
    return documentsModule.filters
  }

  private get bookmarked(): boolean {
    return documentsModule.bookmarked
  }

  private get presets(): DocumentPresetResource[] | null {
    return orderBy(documentsModule.presets, ['key'], ['asc'])
  }

  private get selectablePresets(): SelectItem[] {
    return this.presets?.map(({ id, name }) => ({ label: name, value: id })) ?? []
  }

  private get selectedDocuments(): DocumentCollectionResource[] {
    return documentsModule.selectedDocuments
  }

  private get allDocumentsSelected(): boolean {
    return this.index?.data?.every(
        (document) => this.selectedDocuments.some((selectedDocument) => document.id === selectedDocument.id)
    ) ?? false
  }

  private get selectedPreset(): DocumentPresetResource | undefined {
    return this.presets
      ? this.presets.find(
          (preset: DocumentPresetResource) =>
            preset.id === documentsModule.selectedPreset,
        )
      : undefined
  }

  private get filteredPresets(): {
    [key: string]: DocumentPresetResource[]
  } | null {
    if (this.presets) {
      return {
        mine: this.showAllMinePresets
          ? this.presets.filter((preset) => !preset.predefined)
          : this.presets.filter((preset) => !preset.predefined).splice(0, 5),
        predefined: this.showAllPredefinedPresets
          ? this.presets.filter((preset) => preset.predefined)
          : this.presets.filter((preset) => preset.predefined).splice(0, 5),
      }
    }
    return null
  }

  private get hasDocuments(): boolean {
    return !!this.index && this.index.data.length > 0
  }

  private get hasDocumentOpen(): boolean {
    return !!this.$route.params.document_id
  }

  private get filtersActive(): boolean {
    return !isEqual({ ...this.selectedFilters }, DEFAULT_FILTERS)
  }

  // Lifecycle hooks
  private async beforeRouteEnter(to: Route, from: Route, next: any): Promise<void> {
    stateModule.setLoading(true)
    try {
      // Set preset if coming from query
      if (to.query.presets) {
        await documentsModule.getPresets()
        await documentsModule.getFilters({})
        await documentsModule.setPreset(parseInt(to.query.presets.toString(), 10))

        await Promise.all([
          documentsModule.get(documentsModule.query as DocumentIndexRequest),
        ])
        documentsModule.updateFilters()

        next((vm: any) => {
          vm.setQuery()
        })
      } else {
        await Promise.all([
          documentsModule.get(to.query as DocumentIndexRequest),
          documentsModule.getFilters(to.query as DocumentIndexRequest),
          documentsModule.getPresets(),
        ])
        documentsModule.setPreset()

        next()
      }
    } catch ({ message }) {
      next(from.path || { name: 'dashboard' })
    } finally {
      stateModule.setLoading(false)
    }
  }

  private async getDocuments(): Promise<void> {
    try {
      const to = this.$route
      await Promise.all([
        documentsModule.get(to.query as DocumentIndexRequest),
        documentsModule.getFilters(to.query as DocumentIndexRequest),
        documentsModule.getPresets()
      ])
      documentsModule.setPreset()

      // Set preset if coming from query
      if (to.query.presets) {
        documentsModule.setPreset(parseInt(to.query.presets.toString(), 10))
      }
    } catch (e) {
      console.error(e)
    }
  }

  private created(): void {
    this.getOptions()
  }

  private beforeDestroy(): void {
    this.clearFilters(false)
    this.setParam('search', '')
    documentsModule.setParam({ key: 'search', value: '' })
  }

  private destroyed(): void {
    documentsModule.removeIndex()
    documentsModule.resetSelectedDocuments()
  }

  // computed methods

  private isDraggable(document: DocumentCollectionResource): boolean {
    return false
    return this.$route.params.document_id
      ? parseInt(this.$route.params.document_id, 10) !== document.id
      : false
  }

  private isActivePreset(id: number): boolean {
    return this.selectedPreset ? this.selectedPreset.id === id : false
  }

  private getComponent(type: string): string {
    if (type === 'dropdown') {
      return 'DropdownInput'
    } else if (type === 'date') {
      return 'DateInput'
    } else {
      return 'TextInput'
    }
  }

  private isSelected(document: DocumentCollectionResource): boolean {
    return !!this.selectedDocuments.find((d) => d.id === document.id)
  }

  // Methods
  private hasMorePresets(key: string): boolean {
    if (this.presets && key === 'predefined') {
      return this.presets.filter((preset) => preset.predefined).length > 5
    } else if (this.presets) {
      return this.presets.filter((preset) => !preset.predefined).length > 5
    }
    return false
  }

  private isPresetOpen(key: string): boolean {
    if (key === 'predefined') {
      return this.showAllPredefinedPresets
    } else {
      return this.showAllMinePresets
    }
  }

  private togglePresets(key: string): void {
    if (key === 'predefined') {
      this.showAllPredefinedPresets = !this.showAllPredefinedPresets
    } else {
      this.showAllMinePresets = !this.showAllMinePresets
    }
  }

  private deletePreset(preset: DocumentPresetResource) {
    documentsModule.deletePreset({ preset })
    documentsModule.setPreset()
  }

  private updateSelectedDocuments(document: DocumentCollectionResource): void {
    documentsModule.selectDocument(document)
  }

  private toggleSelectAllDocuments(): void {
    documentsModule.toggleSelectedDocuments()
  }

  private toggleFilters(): void {
    this.showFilters = !this.showFilters
  }

  private clearFilters(submit: boolean = true): void {
    documentsModule.setPreset()
    documentsModule.resetFilter()
    documentsModule.setParam({ key: 'page', value: 1 })
    if (submit) {
      this.$nextTick(() => {
        this.submit()
      })
    }
  }

  private getRowElements(
    document: DocumentCollectionResource,
  ): TableDataElement[] {
    const data: TableDataElement[] = []
    if (this.index && this.index.elements) {
      this.index.elements.forEach((element: TableElement) => {
        const KEY = element.key as keyof DocumentCollectionResource
        let description: string | undefined
        if (KEY === 'embedment' && Array.isArray(document.projects) && document.projects.length) {
          description = document.projects.map((project, index) => `${index + 1}. ${project.name}`).join('<br />')
        }
        const VAL = document[KEY]
        if (
          typeof VAL === 'string' ||
          typeof VAL === 'number' ||
          typeof VAL === 'boolean'
        ) {
          data.push({
            value: VAL,
            type: element.type,
            width: element.width,
            description
          })
        } else {
          data.push({
            value: '-',
            type: element.type,
            width: element.width,
            description
          })
        }
      })
    }
    return data
  }

  private setOrder(col: string, dir: string): void {
    documentsModule.setOrder({ col, dir })
    this.submit()
  }

  private setFilter(key: string, value: string): void {
    documentsModule.setFilter({ key, value })
    // Always set back to first page after applying a filter
    documentsModule.setParam({ key: 'page', value: 1 })
  }

  private setParamAndSubmit(key: string, value: string): void {
    documentsModule.setFilter({ key, value })
    if (key !== 'page') {
      // Always set back to first page after applying a filter
      documentsModule.setParam({ key: 'page', value: 1 })
    }
    this.$nextTick(() => {
      this.submit()
    })
  }

  private setParam(key: string, value: string): void {
    documentsModule.setFilter({ key, value })
    if (key !== 'page') {
      // Always set back to first page after applying a filter
      documentsModule.setParam({ key: 'page', value: 1 })
    }
  }

  private setPreset(id: number): void {
    documentsModule.resetFilter()
    // Reset search
    documentsModule.setFilter({ key: 'search', value: '' })

    documentsModule.setPreset(id)
    // Always set back to first page after applying a filter
    documentsModule.setParam({ key: 'page', value: 1 })
    this.$nextTick(() => {
      this.submit()
    })
  }

  private reloadPreset(id: number): void {
    this.clearFilters(false)

    this.$nextTick(() => {
      this.setPreset(id)
    })
  }

  private switchBookmark(value: boolean): void {
    documentsModule.setParam({ key: 'page', value: 1 })
    documentsModule.setPreset()
    documentsModule.resetFilter()

    documentsModule.setBookmarked(value)
    this.$nextTick(() => {
      this.setParamAndSubmit('search', '')
    })
  }

  private goToPage(val: string): void {
    documentsModule.setParam({ key: 'page', value: val })
    this.$nextTick(() => {
      this.submit()
    })
  }

  private async submitFilter(): Promise<void> {
    await documentsModule.updateFilters()
  }

  private async submitExcludedFilter(key?: string): Promise<void> {
    if (key) {
      await documentsModule.updateFilters(key)
    }
  }

  private async submit(): Promise<void> {
    stateModule.setLoading(true)
    try {
      const data = await documentsModule.get()
      this.setQuery()
      if (data.filters) {
        await documentsModule.updateFilters()
      }
    } finally {
      stateModule.setLoading(false)
    }
  }

  private setQuery(): void {
    const query = cleanObject({
      ...this.query,
      presets: this.selectedPreset ? this.selectedPreset.id : undefined,
    })

    if (this.$route.params.documetId) {
      this.$router.replace({
        name: this.$route.name || '',
        params: { document_id: this.$route.params.documetId },
        query,
      })
    } else {
      this.$router.replace({ name: this.$route.name || '', query })
    }
  }

  private async bookmark(data: DocumentCollectionResource): Promise<void> {
    if (!data.canPerformAction('can_bookmark')) {
      return
    }

    stateModule.setLoading(true)
    try {
      await data.patch({ bookmarked: !data.bookmarked })

      // If document that is patched is the same that is open fetch that document again
      const routeId = parseInt(this.$route.params.document_id, 10)
      if (routeId === data.id) {
        await documentsModule.getById(routeId)
      }
    } finally {
      stateModule.setLoading(false)
    }
  }

  private async markDocumentAsRead(document_id: number): Promise<void> {
    stateModule.setLoading(true)
    try {
      const document = this.index?.data.find((item) => item.id === document_id) ?? null
      if (document) {
        await document.markAsRead()
        await this.getDocuments()
      }
    } catch (e) {
        console.error(e)
    } finally {
      stateModule.setLoading(false)
    }
  }

  private async markAllDocumentsAsRead(): Promise<void> {
    stateModule.setLoading(true)
    try {
      if (this.selectedPreset) {
        await this.selectedPreset.markAllAsRead()
      } else {
        await this.documentsService.markAllAsRead()
      }
    } catch (e) {
      console.error(e)
    } finally {
      await this.getDocuments()
      this.isMarkAsReadModalOpen = false
      stateModule.setLoading(false)
    }
  }

  private togglePostPresetModel(): void {
    this.isCreatePresetModalOpen = !this.isCreatePresetModalOpen
  }

  private openMarkDocumentsAsReadModal(): void {
    this.isMarkAsReadModalOpen = true
  }

  private patchPreset() {
    documentsModule.getPresets()
    this.user?.getPresets()
  }


  private closeCreatePresetModal() {
    this.isCreatePresetModalOpen = false
  }

  private presetCreated(preset: DocumentPresetResource) {
    this.setPreset(preset.id)
    this.user?.getPresets()
  }

  private closeConfirmPatchPresetModal() {
    this.confirmPatchPresetModalOpen = false
  }

  private confirmPatchPreset() {
    this.confirmPatchPresetModalOpen = true
  }

  private async patchPresetFilters({ message }: { message: string }): Promise<void> {
    this.closeConfirmPatchPresetModal()
    if (this.selectedPreset) {
      stateModule.setLoading(true)
      try {
        const filters = cleanObject({ ...this.selectedFilters })
        await this.selectedPreset.patch({ filters, message })
        await this.submit()
        stateModule.setNotification({
          message: `Preset '${this.selectedPreset?.name}' has been updated.`,
          type: 'success',
        })
      } catch ({ errors }) {
        console.error(errors)
      } finally {
        stateModule.setLoading(false)
      }
    }
  }

  private async getOptions(): Promise<void> {
    try {
      const [countries, departments] = await Promise.all([
        this.organisationService.getCountries(),
        this.organisationService.getDepartments(),
      ])
      this.countries = Object.keys(countries).map((key: string) => ({
        value: key,
        label: countries[key],
      }))
      this.departments = departments.map(({ id, name }) => ({
        value: id,
        label: name,
      }))
    } catch (e) {
      console.error(e)
    }
  }

  private getPresetTooltip(type: string): string {
    switch (type) {
      case 'mine':
        return '<strong>My presets</strong>You can quickly repeat a search by saving your search criteria as a preset.<br><br>You will be notified of newly added documents in the library that match your presets'
      case 'predefined':
        return '<strong>Predefined presets</strong>These are presets defined by your organisation. You can not edit these.'
      default:
        return ''
    }
  }

  private documentsExported() {
    this.isExportModalOpen = false
    this.isExportedModalOpen = true
  }


  private toggleExportModal(): void {
    this.isExportModalOpen = !this.isExportModalOpen
  }
}
