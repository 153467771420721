
import { Vue, Component, Prop }    from 'vue-property-decorator'

import DefaultModal from '@/components/modals/Default.vue'

@Component({
  components: {
    DefaultModal,
  }
})
export default class DocumentDetailNotesPartial extends Vue {

  @Prop()
  private isOpen!: boolean

  private close() {
    this.$emit('close')
  }

  private confirm() {
    this.$emit('confirm')
  }

}
