





































import { stateModule, documentsModule } from '@/store'
import { Component, Vue, Model }        from 'vue-property-decorator'

import TextInput           from '@/components/inputs/Text.vue'
import DateInput           from '@/components/inputs/Date.vue'
import DefaultModal        from '@/components/modals/Default.vue'
import DropdownInput       from '@/components/inputs/Dropdown.vue'
import DocumentUploadInput from '@/components/inputs/DocumentUpload.vue'

import { DocumentFilterResource } from '@/models/documents/DocumentFilterResource'
import { DocumentCreateRequest }  from '@/requests/documents/DocumentCreateRequest'

@Component({
  components: {
    TextInput,
    DateInput,
    DefaultModal,
    DropdownInput,
    DocumentUploadInput
  }
})
export default class UploadDocument extends Vue {

  @Model('toggle', { type: Boolean })
  private readonly open!: boolean

  // Data
  private errors: ErrorResponse = {}
  private form: DocumentCreateRequest = {
    file: '',
    subject: [],
    topic: [],
    country: [],
    issuer: []
  }

  private get uploadFilters(): DocumentFilterResource[] | null {
    return this.filters ? this.filters.filter((filter: DocumentFilterResource) => ['subject', 'topic', 'country', 'issuer'].indexOf(filter.key) > -1) : null
  }

  private get filters(): DocumentFilterResource[] | null {
    return documentsModule.filters
  }

  private get formFilled(): boolean {
    return this.form.file !== '' && this.form.file !== undefined && this.form.subject.length > 0 && this.form.topic.length > 0 && this.form.country.length > 0 && this.form.issuer.length > 0
  }

  private getComponent(type: string): string {
    if (type === 'dropdown') {
      return 'DropdownInput'
    } else if (type === 'date') {
      return 'DateInput'
    } else {
      return 'TextInput'
    }
  }

  private async uploadDocument(): Promise<void> {
    stateModule.setLoading(true)
    try {
      await documentsModule.post(this.form)
      this.close()
    } finally {
      stateModule.setLoading(false)
    }
  }

  private close(): void {
    this.$emit('toggle', false)
  }

}
