

























import { stateModule } from '@/store'
import { DocumentsService } from '@/services/documents'
import { Component, Vue, Prop, Model } from 'vue-property-decorator'

import SmallLoader from '@/components/loaders/SmallLoader.vue'

@Component({
  components: {
    SmallLoader
  }
})
export default class DocumentUpload extends Vue {

  @Model('input')
  private readonly value!: File | undefined

  @Prop(String)
  private readonly placeholder!: string

  @Prop(String)
  private readonly label!: string

  @Prop(String)
  private readonly name!: string

  @Prop(String)
  private readonly placeholderImage!: string

  @Prop(Array)
  private readonly errors!: string[]

  private documentService: DocumentsService = new DocumentsService()

  private loading: boolean = false

  private async onChange(files: FileList): Promise<void> {
    if (files.length > 0) {
      this.loading = true
      this.$emit('input', files[0])
    }
  }

  private removeFile(): void {
    this.$emit('input', undefined)
  }

}
