















































































































































import { Component, Vue, Prop }                     from 'vue-property-decorator'
import { stateModule, documentsModule, userModule } from '@/store'
import { ProjectsService }                          from '@/services/projects'
import truncate                                     from 'lodash/truncate'

// components
import Modal from '@/components/modals/Default.vue'

import { DocumentCollectionResource } from '@/models/documents/DocumentCollectionResource'
import { ProjectCollectionResource }  from '@/models/projects/ProjectCollectionResource'
import TableHeading from '@/components/table/Heading.vue'
import TableWrapper from '@/components/table/Wrapper.vue'
import TableRow from '@/components/table/Row.vue'
import Pagination from '@/components/paginations/Pagination.vue'
import SearchInput from '@/components/inputs/Search.vue'

@Component({
  components: {
    Modal,
    TableHeading,
    TableWrapper,
    TableRow,
    Pagination,
    SearchInput,
  }
})
export default class DocSelected extends Vue {
  // Props
  @Prop()
  private selected!: DocumentCollectionResource[]

  // Data
  private index: IndexResponse<ProjectCollectionResource> | null = null
  private projectsService: ProjectsService = new ProjectsService()
  private isReviewing: boolean = false
  private selectedProject: ProjectCollectionResource | null = null
  private namespace: string = 'projects'
  private params: IndexParameters = {
    column: 'created_at',
    dir: 'desc',
    search: '',
    per_page: '10',
    page: '1'
  }

  private get selectedFilesCount(): number {
    return this.selected.length
  }

  private get hasSelectedFiles(): boolean {
    return this.selectedFilesCount > 0
  }

  private get hasData(): boolean {
    return !!this.index && this.index.data.length > 0
  }

  // Lifecycle hooks
  private created(): void {
    this.getProjects()
  }

  private async getProjects(): Promise<void> {
    if (userModule.canPerformAction('can_access_projects')) {
      this.index = await this.projectsService.get(this.params)
      if (typeof this.$route.query.project_id === 'string') {
        const id = parseInt(this.$route.query.project_id, 10)
        this.selectedProject = this.index.data.find((proj) => proj.id === id) || null
      }
    }
  }

  // Methods
  private cancel(): void {
    if (this.isReviewing) {
      this.isReviewing = false
    } else {
      documentsModule.resetSelectedDocuments()
    }
  }

  private deleteDocument(document: DocumentCollectionResource): void {
    if (this.hasSelectedFiles) {
      documentsModule.selectDocument(document)
    }
  }

  private get selectedIds(): number[] {
    return this.selected.map((d) => (d.id))
  }

  private getTruncatedDocumentName(name: string): string {
    return truncate(name, { length: 65 })
  }

  private getTruncatedProjectName(name: string): string {
    return truncate(name, { length: 15 })
  }

  private addToProject(project: ProjectCollectionResource): void {
    this.selectedProject = this.selectedProject && this.selectedProject.id === project.id ? null : project
  }

  private goToPage(val: string): void {
    this.params.page = val
    this.$nextTick(() => {
      this.getProjects()
    })
  }

  private review(): void {
    if (this.isReviewing) {
      this.submit()
    } else {
      this.isReviewing = true
    }
  }

  private addMoreFiles(): void {
    this.isReviewing = !this.isReviewing
  }

  private resetSelected(): void {
    documentsModule.resetSelectedDocuments()
  }

  private async submit(): Promise<void> {
    if (!this.selectedProject) {
      console.error('No project selected')
      return
    }
    stateModule.setLoading(true)
    try {
      await this.selectedProject.linkDocuments({ document_id: this.selectedIds })
      documentsModule.resetSelectedDocuments()
      await this.$router.push({
        name: 'projects-single-detail-documents',
        params: { project_id: this.selectedProject.id.toString() }
      })
      this.isReviewing = false
    } finally {
      stateModule.setLoading(false)
    }
  }

  private getRowElements(document: ProjectCollectionResource): TableDataElement[] {
    const data: TableDataElement[] = []
    if (this.index && this.index.elements) {
      this.index.elements.forEach((element: TableElement) => {
        data.push({
          value: document[element.key as keyof ProjectCollectionResource] as string,
          type: element.type,
        })
      })
    }
    return data
  }

  private setOrder(col: string, dir: string): void {
    this.params.column = col
    this.params.dir = dir
    this.params.page = '1'
    this.getProjects()
  }

  private setSearch(value: string): void {
    this.params.search = value
  }
}
